import Header from "components/header";
import Footer from "components/footer";
import {
    ContainerMedium,
    ContainerSmall,
    StyleTitlePageInternal,
} from "globalStyles";
import { menuList } from "components/utils/constants";
import StyleWrapperPage, {
    StyleSection,
} from "./style";

export default () => {


    return (
        <StyleWrapperPage>
            <Header menuList={menuList} />
            <StyleSection>
                <ContainerMedium style={{ maxWidth: "765px" }}>
                    <StyleTitlePageInternal>Ganhadores</StyleTitlePageInternal>
                    <div
                        className="table-responsive p-2"
                        style={{ backgroundColor: "rgb(229, 0, 75)" }}
                    >
                        <table
                            style={{ minWidth: "450px" }}
                            className="table table-condensed table-bordered table-sm"
                        >
                            <thead>
                                <tr>
                                    <th colspan="3">
                                        <strong>1º SORTEIO</strong>
                                    </th>
                                </tr>
                                <tr>
                                    <th>COLOCAÇÃO</th>
                                    <th>NOME </th>
                                    <th>NÚMERO DA SORTE </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Andréia Tereza de Matos</td>
                                    <td>05-84573</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Jairo Antônio Rosa</td>
                                    <td>05-09607</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Maria de Fátima Maia </td>
                                    <td>05-10455</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Márcia Nunes Pimentel de Azevedo </td>
                                    <td>05-13001</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>ITAMARA RODRIGUES DE MELO</td>
                                    <td>06-71525</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Geraldo M Almeida</td>
                                    <td>06-78585</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Camila Maia Almeida</td>
                                    <td>06-39573</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Daiane Cabral</td>
                                    <td>06-01503</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Maéli Ágata dos Santos Reis </td>
                                    <td>06-04304</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>
                                        Alessandra da Conceição Miranda Santos{" "}
                                    </td>
                                    <td>06-16132</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Jhonas Henrique Pereira </td>
                                    <td>04-68447</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Sueli Cavalcanti Marques</td>
                                    <td>04-69310</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>
                                        Jandira Aparecida de Oliveira Gomes{" "}
                                    </td>
                                    <td>04-54485</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Ivone Rodrigues de Souza Almeida </td>
                                    <td>04-37714</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Ariane Monique Pimentel </td>
                                    <td>04-10225</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Gabriel Jairo Rosa</td>
                                    <td>04-21467</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Silvia Cavalcanti Marques Gonçalves</td>
                                    <td>04-14435</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>DAYANE ANDRADE CHAGAS</td>
                                    <td>07-56507</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Vitoria Camille </td>
                                    <td>08-64941</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Jessica Gonçalves Salgueiro </td>
                                    <td>08-18006</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Eduardo Dourador Fachin Junior</td>
                                    <td>02-52777</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Maria do Carmo de Lima Santos </td>
                                    <td>09-88983</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Rosa de Fátima de Lima Santos</td>
                                    <td>01-56665</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Isadora Vilela Oliveira</td>
                                    <td>00-38351</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div
                        className="table-responsive p-2"
                        style={{ backgroundColor: "rgb(229, 0, 75)" }}
                    >
                        <table
                            style={{ minWidth: "450px" }}
                            className="table table-condensed table-bordered table-sm"
                        >
                            <thead>
                                <tr>
                                    <th colspan="3">
                                        <strong>2º SORTEIO</strong>
                                    </th>
                                </tr>
                                <tr>
                                    <th>COLOCAÇÃO</th>
                                    <th>NOME </th>
                                    <th>NÚMERO DA SORTE </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Kamilly Fernanda da silva </td>
                                    <td>03-67946</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>GABRIELLA LARYSSA DE OLIVEIRA BRAGA</td>
                                    <td>03-96040</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Aline do nascimento Faria</td>
                                    <td>03-05300</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Gina Falkiévicz Maciel</td>
                                    <td>03-46111</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>BEATRIZ DOS SANTOS ATAIDE</td>
                                    <td>03-14070</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Ironildo dos santos pontes</td>
                                    <td>03-19832</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Luanda da Silva Campos</td>
                                    <td>04-83145</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Ângela Maria Souza da Silva </td>
                                    <td>04-64631</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Taina Monteiro dos santos </td>
                                    <td>04-57149</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Humberto Edmar Kuradomi</td>
                                    <td>04-52332</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Emilia Aparecida Parreira </td>
                                    <td>04-46680</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Émellin</td>
                                    <td>04-44961</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Renata Alegre Pernambuco Pimrntel</td>
                                    <td>04-38927</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Claudiane da Silva Moreira </td>
                                    <td>04-37667</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Fabricia Lima de Almeida </td>
                                    <td>04-33210</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Ceilane Santana Leite </td>
                                    <td>04-27904</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Magna ribeiro da Silva </td>
                                    <td>02-73901</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Gina Lee Chan</td>
                                    <td>02-94066</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Isabel Cristina da conceição costa </td>
                                    <td>02-52493</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>MARLENE VIDEIRA ABDALLA</td>
                                    <td>02-04675</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Priscila Aparecida de Barros pontes</td>
                                    <td>02-14375</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Caetana Moreira de barros</td>
                                    <td>02-33308</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Tatiane Aparecida dos Santos Silva</td>
                                    <td>05-74265</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Vinícios de Souza Silva </td>
                                    <td>05-71555</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>CHRISTIAN PENTEADO</td>
                                    <td>05-43933</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Milena Dias dos Santos </td>
                                    <td>05-35643</td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>Denise Soares Fonseca </td>
                                    <td>01-81965</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div
                        className="table-responsive p-2"
                        style={{ backgroundColor: "rgb(229, 0, 75)" }}
                    >
                        <table
                            style={{ minWidth: "450px" }}
                            className="table table-condensed table-bordered table-sm"
                        >
                            <thead>
                                <tr>
                                    <th colspan="3">
                                        <strong>3º SORTEIO</strong>
                                    </th>
                                </tr>
                                <tr>
                                    <th>COLOCAÇÃO</th>
                                    <th>NOME </th>
                                    <th>NÚMERO DA SORTE </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ELLEN NASCIMENTO DA SILVA</td>
                                    <td>01-64558</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Antônia Oneide Vieira da Silva</td>
                                    <td>01-81312</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Cleide Deulefeu Pimentel</td>
                                    <td>01-83243</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Ana Lucia Araújo </td>
                                    <td>01-83532</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>HUERBERT DE OLIVEIRA SANTOS</td>
                                    <td>01-85573</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Pâmela Chaves Fonseca </td>
                                    <td>01-40575</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Natacha Fabrício Crepaldi</td>
                                    <td>01-29212</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Jamili</td>
                                    <td>01-28716</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>WANIA DELGADO GOMES</td>
                                    <td>01-27274</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>CRISTIANE VIEIRA CORREIA</td>
                                    <td>01-24409</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Ana Carolina Nogueira Camargo </td>
                                    <td>01-05321</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Natália da Silva Branco</td>
                                    <td>01-09524</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Juan de matos Andrade </td>
                                    <td>02-60724</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Sandra Mara Alves </td>
                                    <td>02-56925</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Angela Maria Franco da Silva</td>
                                    <td>02-55150</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Rosângela Muniz Marques </td>
                                    <td>02-78283</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Cintia Guedes </td>
                                    <td>02-85357</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Iris Silva de Araújo</td>
                                    <td>02-02148</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Jéssica da Silva Rodrigues </td>
                                    <td>02-27184</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Juanna Lorena Lucena Ferreira </td>
                                    <td>02-02181</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Elaine</td>
                                    <td>02-06913</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Maria Célia Gonçalves de Souza</td>
                                    <td>02-21857</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Karina Macedo da silva </td>
                                    <td>02-07840</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>
                                        Diana Cristina da Silva Alves Macedo{" "}
                                    </td>
                                    <td>02-09235</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>MARIANE GOMES MARINHO</td>
                                    <td>02-16100</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Paula Cristina de Souza Manoel </td>
                                    <td>00-64893</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div
                        className="table-responsive p-2"
                        style={{ backgroundColor: "rgb(229, 0, 75)" }}
                    >
                        <table
                            style={{ minWidth: "450px" }}
                            className="table table-condensed table-bordered table-sm"
                        >
                            <thead>
                                <tr>
                                    <th colspan="3">
                                        <strong>4º SORTEIO</strong>
                                    </th>
                                </tr>
                                <tr>
                                    <th>COLOCAÇÃO</th>
                                    <th>NOME </th>
                                    <th>NÚMERO DA SORTE </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>CRISTIANE COIMBRA EGUFO</td>
                                    <td>01-03455</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Karine Helen </td>
                                    <td>01-04555</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Vanda Silveira</td>
                                    <td>01-06096</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Edilene da Silva Ribeiro</td>
                                    <td>01-00704</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Monica do socorro Monteiro cardoso</td>
                                    <td>01-00390</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>leandro da conceição rasquinha</td>
                                    <td>01-06651</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>JOSEANE Silva Aragao</td>
                                    <td>01-00242</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Patricia Karina Lopes de Souza </td>
                                    <td>01-99153</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Nilcilene Sousa Trindade</td>
                                    <td>01-08789</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>VIVIAN CLEMENTINO ALVES DE SIQUEIRA</td>
                                    <td>01-10070</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>MARCELO RODRIGO BITENCOURT</td>
                                    <td>01-95852</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Maria Áurea de Oliveira Santos </td>
                                    <td>01-95667</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Deborah Christie Alves Da Silva</td>
                                    <td>01-12665</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Luane Amorim Franca dos Santos</td>
                                    <td>01-14061</td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Amanda Deccache Wurthmann</td>
                                    <td>01-14520</td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Eliane Gonçalves de Farias </td>
                                    <td>01-16075</td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Cristiane Alves de Oliveira </td>
                                    <td>01-17757</td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>
                                        Flávia Hidelgardenia Santos Pinheiro{" "}
                                    </td>
                                    <td>01-87248</td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>GISELLE REGINA LESTE</td>
                                    <td>01-20044</td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>NILSON GOMES DA SILVA</td>
                                    <td>01-20187</td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Rosa Maria </td>
                                    <td>01-85997</td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Nilza de Sousa Estrela Barros</td>
                                    <td>01-23110</td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Kathlen Costa Barbosa </td>
                                    <td>01-25137</td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Debora Caldeira Bom</td>
                                    <td>01-81237</td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Líndice da Silva Gomes</td>
                                    <td>01-26840</td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Alberanice Barreto da Silva </td>
                                    <td>01-29511</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ContainerMedium>
                <Footer />
            </StyleSection>
        </StyleWrapperPage>
    );
};
